import React, { useState, useEffect } from 'react';
import { X, Plus, Trash2, Star, Minus } from 'lucide-react';
import {PhotoFile, PriceInput} from '@/types/photo';
import toast from "react-hot-toast";
import {THUMBNAIL} from "@utils/constants.ts";
import {PhotoFormModalProps} from "@/types/props.ts";

const MAX_PHOTOS = 9;

const PhotoFormModal: React.FC<PhotoFormModalProps> = ({
                                                           isOpen,
                                                           onClose,
                                                           onSubmit,
                                                           photo
                                                       }) => {
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [detailDescription, setDetailDescription] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [order, setOrder] = useState('0');
    const [age, setAge] = useState('');
    const [height, setHeight] = useState('');
    const [photos, setPhotos] = useState<PhotoFile[]>([]);
    const [prices, setPrices] = useState<PriceInput[]>([]);
    const [location, setLocation] = useState('')

    // Validation states
    const [ageError, setAgeError] = useState(false);
    const [heightError, setHeightError] = useState(false);

    useEffect(() => {
        if (photo) {
            setTitle(photo.title);
            setShortDescription(photo.short_description);
            setDetailDescription(photo.detail_description);
            setStartTime(photo.start_time.split('T')[0]);
            setEndTime(photo.end_time.split('T')[0]);
            setOrder(String(photo.order));
            setAge(photo.age ? String(photo.age) : '');
            setHeight(photo.height ? String(photo.height) : '');
            setPrices(
                photo.prices.map(p => ({
                    price: String(p.price),
                    time: String(p.time),
                    description: p.description
                }))
            );
            setLocation(photo.location)

            // 加载现有照片
            // 将已有照片转换为PhotoFile格式
            const existingPhotos: PhotoFile[] = photo.photo.map((photoUrl, index) => ({
                file: null as any,  // 编辑模式下，我们不需要File对象，因为图片已经上传
                preview: photoUrl,
                isThumbnail: photoUrl === photo.thumbnail
            }));
            setPhotos(existingPhotos);
        }
    }, [photo]);

    // Cleanup previews on unmount
    useEffect(() => {
        return () => {
            photos.forEach(photo => URL.revokeObjectURL(photo.preview));
        };
    }, [photos]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        const remainingSlots = MAX_PHOTOS - photos.length;

        if (files.length > remainingSlots) {
            toast.error(`你只能再多添加${remainingSlots}张照片`)
            return;
        }

        const newPhotos = files.map((file, index) => ({
            file,
            preview: URL.createObjectURL(file),
            isThumbnail: photos.length === 0 && index === 0 // Only the first photo is thumbnail when no existing photos
        }));

        setPhotos(prev => [...prev, ...newPhotos]);
    };

    const handleRemovePhoto = (index: number) => {
        setPhotos(prev => {
            const newPhotos = [...prev];
            URL.revokeObjectURL(newPhotos[index].preview);
            newPhotos.splice(index, 1);

            // If we removed the thumbnail, set the first remaining photo as thumbnail
            if (newPhotos.length > 0 && !newPhotos.some(p => p.isThumbnail)) {
                newPhotos[0].isThumbnail = true;
            }

            return newPhotos;
        });
    };

    const setAsThumbnail = (index: number) => {
        setPhotos(prev => prev.map((photo, i) => ({
            ...photo,
            isThumbnail: i === index
        })));
    };

    const validateNumberField = (value: string, setter: (valid: boolean) => void) => {
        const numValue = Number(value);
        setter(numValue <= 0);
        return numValue > 0;
    };

    const handlePriceChange = (index: number, field: keyof PriceInput, value: string) => {
        const newPrices = [...prices];
        newPrices[index][field] = value;
        setPrices(newPrices);
    };

    const handleAddPrice = () => {
        setPrices([...prices, { price: '', time: '', description: '' }]);
    };

    const handleRemovePrice = (index: number) => {
        const newPrices = prices.filter((_, i) => i !== index);
        setPrices(newPrices);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Validate age and height
        const isAgeValid = !age || validateNumberField(age, setAgeError);
        const isHeightValid = !height || validateNumberField(height, setHeightError);

        if (!isAgeValid || !isHeightValid) {
            return;
        }

        const formData = new FormData();
        let thumbnail_filename = ''
        let photo_filenames: string[] = []

        // Add all photos
        photos.forEach((photo, index) => {
            const photo_filename = photo.file ? photo.file.name : photo.preview
            photo_filenames.push(photo_filename)
            if (photo.file) {
                formData.append('files', photo.file);
            }
            if (photo.isThumbnail) {
                thumbnail_filename = photo_filename
            }
        });

        const photoData = {
            title: title,
            short_description: shortDescription,
            detail_description: detailDescription,
            start_time: startTime,
            end_time: endTime,
            order: parseInt(order),
            age: parseInt(age),
            height: parseInt(height),
            prices: prices.map(p => ({
                price: parseFloat(p.price),
                time: parseInt(p.time),
                description: p.description
            })),
            thumbnail_filename: thumbnail_filename,
            photo_filenames: photo_filenames,
            location: location
        };

        formData.append('data', JSON.stringify(photoData));
        await onSubmit(formData);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-6 border-b">
                    <h2 className="text-xl font-semibold">
                        {photo ? 'Edit Photo' : 'Add New Photo'}
                    </h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X className="w-6 h-6" />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="p-6 space-y-6">
                    {/* Photos Upload Section */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Photos ({photos.length}/{MAX_PHOTOS})
                        </label>
                        <div className="grid grid-cols-3 gap-4">
                            {photos.map((photo, index) => (
                                <div key={index} className="relative group">
                                    <img
                                        src={photo.preview}
                                        alt={`Upload ${index + 1}`}
                                        className="w-full h-32 object-cover rounded"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center space-x-2">
                                        <button
                                            type="button"
                                            onClick={() => setAsThumbnail(index)}
                                            className={`p-1 rounded-full ${photo.isThumbnail ? 'bg-yellow-500' : 'bg-white'}`}
                                            title="Set as thumbnail"
                                        >
                                            <Star className="w-4 h-4" />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleRemovePhoto(index)}
                                            className="p-1 rounded-full bg-white text-red-500"
                                        >
                                            <Trash2 className="w-4 h-4" />
                                        </button>
                                    </div>
                                    {photo.isThumbnail && (
                                        <div className="absolute top-1 right-1 bg-yellow-500 text-xs px-2 py-1 rounded text-white">
                                            {THUMBNAIL}
                                        </div>
                                    )}
                                </div>
                            ))}
                            {photos.length < MAX_PHOTOS && (
                                <label className="border-2 border-dashed border-gray-300 rounded flex items-center justify-center cursor-pointer hover:border-gray-400 h-32">
                                    <input
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        className="hidden"
                                    />
                                    <Plus className="w-8 h-8 text-gray-400" />
                                </label>
                            )}
                        </div>
                    </div>

                    {/* Basic Info */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">标题</label>
                            <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                短介绍
                            </label>
                            <input
                                type="text"
                                value={shortDescription}
                                onChange={(e) => setShortDescription(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            />
                        </div>
                    </div>

                    {/* Display Settings */}
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                照片显示顺序
                            </label>
                            <input
                                type="number"
                                value={order}
                                onChange={(e) => setOrder(e.target.value)}
                                min="0"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                年龄
                            </label>
                            <div className="relative">
                                <input
                                    type="number"
                                    value={age}
                                    onChange={(e) => {
                                        setAge(e.target.value);
                                        validateNumberField(e.target.value, setAgeError);
                                    }}
                                    className={`mt-1 block w-full border rounded-md shadow-sm p-2 
                    ${ageError ? 'border-red-500' : 'border-gray-300'}`}
                                />
                                {ageError && (
                                    <p className="absolute text-red-500 text-xs mt-1">
                                        年龄必须大于0
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                身高
                            </label>
                            <div className="relative">
                                <input
                                    type="number"
                                    value={height}
                                    onChange={(e) => {
                                        setHeight(e.target.value);
                                        validateNumberField(e.target.value, setHeightError);
                                    }}
                                    className={`mt-1 block w-full border rounded-md shadow-sm p-2 
                    ${heightError ? 'border-red-500' : 'border-gray-300'}`}
                                />
                                {heightError && (
                                    <p className="absolute text-red-500 text-xs mt-1">
                                        身高必须大于0
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                地区
                            </label>
                            <input
                                type="text"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            />
                        </div>
                    </div>

                    {/* Time Range */}
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                开始时间
                            </label>
                            <input
                                type="date"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                结束时间
                            </label>
                            <input
                                type="date"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            服务具体介绍
                        </label>
                        <textarea
                            value={detailDescription}
                            onChange={(e) => setDetailDescription(e.target.value)}
                            rows={4}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>

                    {/* Prices Section */}
                    <div>
                        <div className="flex justify-between items-center mb-2">
                            <label className="block text-sm font-medium text-gray-700">价格信息</label>
                            <button
                                type="button"
                                onClick={handleAddPrice}
                                className="text-indigo-600 hover:text-indigo-800 flex items-center text-sm"
                            >
                                <Plus className="w-4 h-4 mr-1" />
                                添加价格信息
                            </button>
                        </div>
                        <div className="space-y-4">
                            {prices.map((price, index) => (
                                <div key={index} className="flex items-start space-x-2">
                                    <div className="flex-grow grid grid-cols-3 gap-2">
                                        <div>
                                            <input
                                                type="number"
                                                value={price.price}
                                                onChange={(e) => handlePriceChange(index, 'price', e.target.value)}
                                                placeholder="价格"
                                                required
                                                min="0"
                                                className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            />
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                value={price.time}
                                                onChange={(e) => handlePriceChange(index, 'time', e.target.value)}
                                                placeholder="时间(分钟)"
                                                required
                                                min="0"
                                                className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            />
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                value={price.description}
                                                onChange={(e) => handlePriceChange(index, 'description', e.target.value)}
                                                placeholder="说明"
                                                required
                                                className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleRemovePrice(index)}
                                        className="text-red-600 hover:text-red-800"
                                    >
                                        <Minus className="w-4 h-4"/>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex justify-end space-x-4 pt-4 border-t">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                        >
                            取消
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                        >
                            {photo ? '保存' : '添加'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PhotoFormModal;