import React from 'react';
import { FooterProps } from "@/types/props.ts";
import { Phone, MessageCircle } from 'lucide-react';

const Footer: React.FC<FooterProps> = ({ webInfo }) => {
    return (
        <footer className="bg-gray-50 mt-auto">
            <div className="max-w-7xl mx-auto px-4 py-12">
                <div className="space-y-8">
                    {webInfo?.description_title && (
                        <h3 className="text-lg font-medium text-gray-900 text-center">
                            {webInfo.description_title}
                        </h3>
                    )}
                    {webInfo?.description_detail && (
                        <p className="text-gray-600 max-w-3xl mx-auto whitespace-pre-wrap text-left">
                            {webInfo.description_detail}
                        </p>
                    )}

                    {/* 联系方式区域 - 使用与上面文字相同的 max-w-3xl 类 */}
                    <div className="max-w-3xl mx-auto">
                        <div className="bg-gradient-to-r from-pink-50 to-purple-50 rounded-2xl p-6 shadow-lg border border-pink-100">
                            <h4 className="text-center text-xl font-medium text-pink-600 mb-6">
                                💝 {webInfo?.store_name} 💝
                            </h4>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {/* 左侧：电话 */}
                                <div className="bg-white/80 backdrop-blur-sm rounded-xl p-4 shadow-sm hover:shadow-md transition-shadow">
                                    <div className="flex items-center text-pink-500">
                                        <Phone className="w-5 h-5 mr-2" />
                                        <span className="font-medium">联系电话</span>
                                    </div>
                                    <p className="mt-2 text-gray-600">{webInfo?.phone}</p>
                                </div>

                                {/* 右侧：社交媒体 */}
                                <div className="space-y-3">
                                    {webInfo?.wechat && (
                                        <div className="flex items-center bg-white/80 backdrop-blur-sm rounded-lg px-4 py-2 shadow-sm">
                                            <MessageCircle className="w-4 h-4 text-green-500 mr-2" />
                                            <span className="text-gray-600">微信: {webInfo.wechat}</span>
                                        </div>
                                    )}

                                    {webInfo?.line && (
                                        <div className="flex items-center bg-white/80 backdrop-blur-sm rounded-lg px-4 py-2 shadow-sm">
                                            <MessageCircle className="w-4 h-4 text-blue-500 mr-2" />
                                            <span className="text-gray-600">Line: {webInfo.line}</span>
                                        </div>
                                    )}

                                    {webInfo?.whatsapp && (
                                        <div className="flex items-center bg-white/80 backdrop-blur-sm rounded-lg px-4 py-2 shadow-sm">
                                            <MessageCircle className="w-4 h-4 text-emerald-500 mr-2" />
                                            <span className="text-gray-600">WhatsApp: {webInfo.whatsapp}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;