import axiosInstance from './axiosConfig';
import { useMutation } from '@tanstack/react-query';
import {LOGIN} from "@utils/urls.ts";

interface LoginCredentials {
    username: string;
    password: string;
}

export const useLogin = () => {
    return useMutation({
        mutationFn: async (credentials: LoginCredentials) => {
            const params = new URLSearchParams();
            params.append('username', credentials.username);
            params.append('password', credentials.password);
            const response = await axiosInstance.post(LOGIN, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            return response.data;
        }
    });
};

export const useLogout = () => {
    return useMutation({
        mutationFn: async () => {
            localStorage.removeItem('accessToken');
            axiosInstance.defaults.headers.common['Authorization'] = '';
        }
    });
};