import React, { useState, useEffect } from 'react';
import { X, Plus, Trash2 } from 'lucide-react';
import { PhotoFile } from '@/types/photo';
import toast from "react-hot-toast";

interface BatchUploadModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (formData: FormData) => Promise<void>;
}

const MAX_PHOTOS = 100; // Increased limit for batch upload

const BatchUploadModal: React.FC<BatchUploadModalProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               onSubmit
                                                           }) => {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [photos, setPhotos] = useState<PhotoFile[]>([]);

    // Cleanup previews on unmount
    useEffect(() => {
        return () => {
            photos.forEach(photo => URL.revokeObjectURL(photo.preview));
        };
    }, [photos]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        const remainingSlots = MAX_PHOTOS - photos.length;

        if (files.length > remainingSlots) {
            toast.error(`你只能再多添加${remainingSlots}张照片`)
            return;
        }

        const newPhotos = files.map(file => ({
            file,
            preview: URL.createObjectURL(file),
            isThumbnail: false
        }));

        setPhotos(prev => [...prev, ...newPhotos]);
    };

    const handleRemovePhoto = (index: number) => {
        setPhotos(prev => {
            const newPhotos = [...prev];
            URL.revokeObjectURL(newPhotos[index].preview);
            newPhotos.splice(index, 1);
            return newPhotos;
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!startTime || !endTime) {
            toast.error('请设置开始和结束时间');
            return;
        }

        if (photos.length === 0) {
            toast.error('请至少上传一张照片');
            return;
        }

        const formData = new FormData();

        // Add all photos
        photos.forEach(photo => {
            if (photo.file) {
                formData.append('files', photo.file);
            }
        });

        const photoData = {
            start_time: startTime,
            end_time: endTime
        };

        formData.append('data', JSON.stringify(photoData));
        await onSubmit(formData);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-6 border-b">
                    <h2 className="text-xl font-semibold">批量上传照片</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X className="w-6 h-6" />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="p-6 space-y-6">
                    {/* Time Range */}
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                开始时间
                            </label>
                            <input
                                type="date"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                结束时间
                            </label>
                            <input
                                type="date"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            />
                        </div>
                    </div>

                    {/* Photos Upload Section */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Photos ({photos.length}/{MAX_PHOTOS})
                        </label>
                        <div className="grid grid-cols-6 gap-4">
                            {photos.map((photo, index) => (
                                <div key={index} className="relative group">
                                    <img
                                        src={photo.preview}
                                        alt={`Upload ${index + 1}`}
                                        className="w-full h-24 object-cover rounded"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                                        <button
                                            type="button"
                                            onClick={() => handleRemovePhoto(index)}
                                            className="p-1 rounded-full bg-white text-red-500"
                                        >
                                            <Trash2 className="w-4 h-4" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                            {photos.length < MAX_PHOTOS && (
                                <label className="border-2 border-dashed border-gray-300 rounded flex items-center justify-center cursor-pointer hover:border-gray-400 h-24">
                                    <input
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        className="hidden"
                                    />
                                    <Plus className="w-8 h-8 text-gray-400" />
                                </label>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end space-x-4 pt-4 border-t">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                        >
                            取消
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                        >
                            上传
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BatchUploadModal;