import React, {useEffect} from "react";
import {PhotoCardProps} from "@/types/props.ts";

export const PhotoCard: React.FC<PhotoCardProps> = ({photo, onPhotoClick}) => {
    const {
        title,
        thumbnail,
        short_description,
        prices,
        location,
        age,
        height
    } = photo;

    return (
        <div
            className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer transform hover:scale-[1.02] h-fit"
            onClick={() => onPhotoClick(photo.id)}
        >
            {/* Thumbnail */}
            <div className="aspect-[3/4] relative">
                <img
                    src={thumbnail}
                    alt={title}
                    className="w-full h-full object-cover"
                    loading="lazy"
                />
            </div>

            {(title || short_description || prices.length !== 0 || location || age || height) && <div className="p-4 space-y-3">
                {/* Tags */}
                <div className="flex flex-col md:flex-row gap-2">
                    {location &&
                        <span className="bg-pink-50 text-pink-600 text-xs px-2 py-1 rounded-full text-center">
                            {location}
                        </span>}
                    {height &&
                        <span className="bg-blue-50 text-blue-600 text-xs px-2 py-1 rounded-full text-center">
                            {height}cm
                        </span>}
                    {age &&
                        <span className="bg-purple-50 text-purple-600 text-xs px-2 py-1 rounded-full text-center">
                            {age}岁
                        </span>}
                </div>

                {/* Title */}
                {title && <h3 className="text-lg font-semibold truncate">{title}</h3>}

                {/* Description */}
                {short_description && <p className="text-sm text-gray-600">{short_description}</p>}

                {/* Prices */}
                {prices &&
                    <div className="space-y-2">
                        {prices?.map((price, index) => (
                            <div key={index} className="flex justify-between items-center bg-gray-50 p-2 rounded-lg">
                                <span className="text-gray-600">{price.time}分钟</span>
                                <span className="font-semibold text-pink-600">${price.price}</span>
                            </div>
                        ))}
                    </div>}
            </div>}
        </div>
    );
};

export default PhotoCard;