// frontend/src/config.ts

interface Config {
  apiUrl: string;
  appTitle: string;
  awsRegion: string;
}

// 开发环境默认值
const DEV_CONFIG: Config = {
  apiUrl: 'http://localhost:8000/api/v1',
  appTitle: 'Photo Gallery (Dev)',
  awsRegion: 'us-east-1',
};

// 生产环境使用环境变量
const PROD_CONFIG: Config = {
  apiUrl: import.meta.env.VITE_API_URL,
  appTitle: import.meta.env.VITE_APP_TITLE || 'Photo Gallery',
  awsRegion: import.meta.env.VITE_AWS_REGION || 'us-east-1',
};

// 根据环境选择配置
const config: Config = import.meta.env.PROD ? PROD_CONFIG : DEV_CONFIG;

// 验证配置并输出调试信息
console.log('Current environment:', import.meta.env.MODE);
console.log('API URL:', config.apiUrl);

if (!config.apiUrl) {
  console.error('API URL is not defined');
}

export const isProd = config.apiUrl ===  PROD_CONFIG.apiUrl

export default config;