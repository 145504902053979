import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePhotos } from '@/services/photoService';
import { NO_PHOTO, PHOTO_LOADING, PHOTO_LOADING_FAILED } from "@utils/constants";
import { HomeProps } from "@/types/props.ts";
import PhotoCard from '@/components/gallery/PhotoCard';
import { MapPin } from 'lucide-react';
import ContactInfo from "@components/common/ContactInfo.tsx";
import ScrollingNotice from "@components/common/ScrollingNotice.tsx";

const Notice: React.FC<{ text: string, index: number }> = ({ text, index }) => {
    // 根据索引选择不同的颜色
    const colors = [
        'bg-gradient-to-r from-pink-50 to-red-50',
        'bg-gradient-to-r from-purple-50 to-pink-50',
        'bg-gradient-to-r from-blue-50 to-purple-50',
        'bg-gradient-to-r from-red-50 to-purple-50'
    ];

    return (
        <div className={`${colors[index % colors.length]} p-4 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300`}>
            <div className="text-gray-700 text-center">
                {text}
            </div>
        </div>
    );
};

const Home: React.FC<HomeProps> = ({webInfo}) => {
    const navigate = useNavigate();
    const { data: photos, isLoading: isLoadingPhotos, error: photosError } = usePhotos();

    const onPhotoClick = (id: string) => {
        navigate(`/photo/${id}`);
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-white to-pink-50">
            <div className="max-w-7xl mx-auto px-4 py-8 space-y-8">
                {/* Contact Information */}
                {webInfo && (
                    <ContactInfo
                        phone={webInfo.phone}
                        wechat={webInfo.wechat}
                        line={webInfo.line}
                        whatsapp={webInfo.whatsapp}
                        open_time={webInfo.open_time}
                        close_time={webInfo.close_time}
                    />
                )}

                {/* Address */}
                {webInfo?.address && (
                    <div className="bg-white/80 backdrop-blur-sm rounded-xl p-4 shadow-sm">
                        <div className="flex items-center justify-center gap-2 text-gray-600">
                            <MapPin className="w-5 h-5 text-pink-500" />
                            <span>{webInfo.address}</span>
                        </div>
                    </div>
                )}

                {/* Notifications */}
                {webInfo?.notification && webInfo.notification.length > 0 && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {webInfo.notification.map((notice, index) => (
                            <Notice key={index} text={notice} index={index} />
                        ))}
                    </div>
                )}

                {/* Carousel Notification */}
                {webInfo?.carousel_notification && (
                    <ScrollingNotice text={webInfo.carousel_notification} />
                )}

                {/* Photos Grid */}
                <div className="space-y-6">
                    {isLoadingPhotos ? (
                        <div className="flex justify-center items-center h-24 text-gray-600">
                            {PHOTO_LOADING}
                        </div>
                    ) : photosError ? (
                        <div className="text-red-600 p-4 text-center bg-red-50 rounded-lg">
                            {PHOTO_LOADING_FAILED}
                        </div>
                    ) : (
                        <>
                            {/* Top Banner */}
                            <div className="text-center">
                                <div className="inline-block bg-gradient-to-r from-pink-500 to-purple-500 text-transparent bg-clip-text text-xl font-bold mb-2">
                                    精选推荐
                                </div>
                                <div className="h-1 w-20 bg-gradient-to-r from-pink-500 to-purple-500 rounded-full mx-auto"/>
                            </div>

                            {/* Photos Grid */}
                            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {photos?.map((photo) => (
                                    <PhotoCard
                                        key={photo.id}
                                        photo={photo}
                                        onPhotoClick={onPhotoClick}
                                    />
                                ))}
                            </div>

                            {photos?.length === 0 && (
                                <div className="text-center text-gray-500 bg-gray-50 rounded-lg p-8">
                                    {NO_PHOTO}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Floating Button */}
            <a
                href={`tel:${webInfo?.phone}`}
                className="fixed bottom-8 right-8 bg-gradient-to-r from-pink-500 to-purple-500 text-white px-6 py-3 rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
                一键拨号 🌸
            </a>
        </div>
    );
};

export default Home;