import React from "react";
import {PrivateRouteProps} from "@/types/props.ts";
import {useAuth} from "@/contexts/AuthContext.tsx";
import {Navigate} from "react-router-dom";

const PrivateRoute: React.FC<PrivateRouteProps> = ({children, adminOnly}) => {
    const {user, isLoading} = useAuth();

    if (isLoading) {
        return <div className="flex justify-center items-center h-24">Loading...</div>;
    }

    if (!user) {
        return <Navigate to="/login"/>;
    }

    if (adminOnly && user.role !== 'ADMIN') {
        return <div className="p-4 text-red-600 bg-red-50 rounded">Permission denied</div>;
    }

    return <>{children}</>;
}

export default PrivateRoute;