import React, {useState} from 'react';
import {Photo} from '@/types/photo';
import {useAllPhotos} from '@/services/photoService';
import {PencilIcon, TrashIcon, PlusIcon} from 'lucide-react';
import PhotoFormModal from './PhotoFormModal';
import BatchUploadModal from './BatchUploadModal'; // 新增: 导入批量上传模态框
import axiosInstance from '@/services/axiosConfig';
import {useQueryClient} from '@tanstack/react-query';
import toast from "react-hot-toast";
import {ACTIONS, ORDER, PHOTO_MANAGEMENT, PHOTO_PREVIEW, TIME_RANGE, TITLE, VIEWS} from "@utils/constants.ts";

const PhotoManagement: React.FC = () => {
    const queryClient = useQueryClient();
    const {data: photos, isLoading, error} = useAllPhotos();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBatchModalOpen, setIsBatchModalOpen] = useState(false); // 新增: 批量上传模态框的状态
    const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);

    // 新增: 批量上传处理函数
    const handleBatchUpload = async (formData: FormData) => {
        const loadingToast = toast.loading('正在上传照片...');
        try {
            await axiosInstance.post('/photos/group-with-files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.dismiss(loadingToast);
            toast.success('照片批量上传成功');
            queryClient.refetchQueries({queryKey: ['photos']});
            setIsBatchModalOpen(false);
        } catch (error) {
            toast.dismiss(loadingToast);
            console.error('Error batch uploading photos:', error);
            toast.error('批量上传失败，请重试');
        }
    };

    const handleAddEdit = async (formData: FormData) => {
        try {
            if (selectedPhoto) {
                // Edit existing photo
                axiosInstance.patch(`/photos/${selectedPhoto.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(res => {
                    toast.success('信息更新成功')
                }).catch(err => {
                    toast.error('照片更新失败，请稍后重试')
                    console.error('Photo update failed: ', err)
                })
            } else {
                // Add new photo
                if (!formData.get('files')) {
                    toast.error('请至少添加一张照片')
                    return
                }
                axiosInstance.post('/photos/with-files', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(res => toast.success('照片建立成功'))
                    .catch(err => {
                        toast.error('照片建立失败，请稍后重试')
                        console.error('Photo creation failed: ', err)
                    })
            }
            // Refresh photos list
            queryClient.refetchQueries({queryKey: ['photos']})
            queryClient.refetchQueries({queryKey: ['photos', 'all']})
            // Close modal
            handleCloseModal();
        } catch (error) {
            console.error('Error saving photo:', error);
            toast.error('添加失败，请重试')
        }
    };

    const handleDelete = async (photoId: string) => {
        if (!window.confirm('确定要删除该照片吗?')) {
            return;
        }

        axiosInstance.delete(`/photos/${photoId}`).then((res) => {
            toast.success('删除成功')
            queryClient.refetchQueries({queryKey: ['photos']})
        })
            .catch(error => {
                console.error('Error deleting photo:', error);
                toast.error('删除照片失败，请重试')
            })
    };

    const handleDeleteAll = async () => {
        if (!window.confirm('确定要删除全部照片吗?')) {
            return;
        }
        const ids = photos?.map(photo => photo.id)
        console.log(ids)
        axiosInstance.post(`/photos/group-delete`, {ids}).then(() => {
            toast.success('删除成功')
            queryClient.refetchQueries({queryKey: ['photos']})
        })
            .catch(error => {
                console.error('Error deleting photo:', error);
                toast.error('删除照片失败，请重试')
            })
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedPhoto(null);
    };

    if (isLoading) {
        return (
            <div className="p-6">
                <div className="flex justify-center items-center h-32">
                    Loading...
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-6">
                <div className="text-red-500">Error loading photos</div>
            </div>
        );
    }

    return (
        <div className="p-6">
            {/* Header */}
            <div
                className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0 mb-6 w-full">
                <h2 className="text-xl font-semibold">{PHOTO_MANAGEMENT}</h2>

                {/* Buttons Container */}
                <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
                    <button
                        onClick={() => setIsBatchModalOpen(true)}
                        className="flex items-center justify-center px-3 py-2 bg-indigo-600 text-white text-sm rounded-md hover:bg-indigo-700 w-full sm:w-auto"
                    >
                        <PlusIcon className="w-4 h-4 mr-1"/>
                        批量添加照片
                    </button>
                    <button
                        onClick={handleDeleteAll}
                        className="flex items-center justify-center px-3 py-2 bg-red-600 text-white text-sm rounded-md hover:bg-red-700 w-full sm:w-auto"
                    >
                        <TrashIcon className="w-4 h-4 mr-1"/>
                        删除全部照片
                    </button>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="flex items-center justify-center px-3 py-2 bg-indigo-600 text-white text-sm rounded-md hover:bg-indigo-700 w-full sm:w-auto"
                    >
                        <PlusIcon className="w-4 h-4 mr-1"/>
                        添加照片
                    </button>
                </div>
            </div>

            {/* Photo List */}
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {ACTIONS}
                        </th>
                        <th className="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[120px] whitespace-normal">
                            {PHOTO_PREVIEW}
                        </th>
                        <th className="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {TITLE}
                        </th>
                        <th className="p-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {VIEWS}
                        </th>
                        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {TIME_RANGE}
                        </th>
                        <th className="p-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {ORDER}
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {photos?.map((photo) => (
                        <tr key={photo.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                <button
                                    onClick={() => {
                                        setSelectedPhoto(photo);
                                        setIsModalOpen(true);
                                    }}
                                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                                >
                                    <PencilIcon className="w-4 h-4"/>
                                </button>
                                <button
                                    onClick={() => handleDelete(photo.id)}
                                    className="text-red-600 hover:text-red-900"
                                >
                                    <TrashIcon className="w-4 h-4"/>
                                </button>
                            </td>
                            <td className="p-2 whitespace-nowrap min-w-[120px]">
                                <img
                                    src={photo.thumbnail}
                                    alt={photo.title}
                                    className="h-20 w-20 object-cover rounded"
                                />
                            </td>
                            <td className="p-2 whitespace-nowrap max-w-1 truncate min-w-[240px]">
                                {photo.title}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right">
                                {photo.view}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right">
                                <div className="text-sm">
                                    <div>Start: {new Date(photo.start_time).toLocaleDateString()}</div>
                                    <div>End: {new Date(photo.end_time).toLocaleDateString()}</div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right">
                                {photo.order + 1}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* Photo Form Modal */}
            {isModalOpen && (
                <PhotoFormModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSubmit={handleAddEdit}
                    photo={selectedPhoto}
                />
            )}

            {/* Batch Upload Modal */}
            {isBatchModalOpen && (
                <BatchUploadModal
                    isOpen={isBatchModalOpen}
                    onClose={() => setIsBatchModalOpen(false)}
                    onSubmit={handleBatchUpload}
                />
            )}
        </div>
    );
};

export default PhotoManagement;