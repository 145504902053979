import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './styles/tailwind.css'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {AuthProvider} from "@/contexts/AuthContext.tsx";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {isProd} from "@/config.ts";
import ToastProvider from "@components/comments/ToastProvider";

const root = document.getElementById('root')
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity, // 数据永不过期
            refetchOnMount: false, // 组件挂载时不重新获取
            refetchOnReconnect: false, // 重新连接时不重新获取
            refetchOnWindowFocus: false // 窗口聚焦时不重新获取
        }
    }
});

if (root) {
    ReactDOM.createRoot(root).render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <BrowserRouter>
                        <ToastProvider/>
                        <App />
                    </BrowserRouter>
                </AuthProvider>
                {!isProd && <ReactQueryDevtools/>}
            </QueryClientProvider>
        </React.StrictMode>,
    )
}