export const PHOTO_LOADING = '照片正在加载'
export const PHOTO_LOADING_FAILED = '加载照片失败，请稍后重试'
export const NO_PHOTO = '暂时没有照片'
export const THUMBNAIL = '封面照'
export const TITLE = '标题'
export const ACTIONS = '管理'
export const PHOTO_PREVIEW = '缩略图'
export const VIEWS = '点击数'
export const TIME_RANGE = '时间'
export const ORDER = '顺序'
export const PHOTO_MANAGEMENT = '照片管理'
export const ADMIN_DASHBOARD = '管理员页面'
export const BACK = '返回'