import React, { useState } from 'react';
import { PencilIcon, TrashIcon, PlusIcon } from 'lucide-react';
import { useGalleries, useCreateGallery, useUpdateGallery, useDeleteGallery } from '@/services/galleryService';
import { Gallery } from '@/types/gallery';
import { usePhotos } from '@/services/photoService';
import GalleryFormModal from "@components/admin/GalleryFormModal.tsx";
import toast from "react-hot-toast";

const GalleryManagement: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState<Gallery | null>(null);

    const { data: galleries, isLoading } = useGalleries();
    const { data: photos } = usePhotos();
    const createGallery = useCreateGallery();
    const updateGallery = useUpdateGallery();
    const deleteGallery = useDeleteGallery();

    const formatDate = (date: string) => {
        return new Date(date).toLocaleString();
    };

    const handleSubmit = async (formData: Partial<Gallery>) => {
        try {
            if (selectedGallery) {
                await updateGallery.mutateAsync({
                    id: selectedGallery.id,
                    data: formData
                });
            } else {
                await createGallery.mutateAsync(formData);
            }
            setIsModalOpen(false);
            setSelectedGallery(null);
        } catch (error) {
            console.error('Error saving gallery:', error);
            toast.error('保存失败，请重试')
        }
    };

    const handleDelete = async (id: string) => {
        if (!window.confirm('Are you sure you want to delete this gallery?')) {
            return;
        }
        try {
            await deleteGallery.mutateAsync(id);
        } catch (error) {
            console.error('Error deleting gallery:', error);
            toast.error('删除失败，请重试')
        }
    };

    if (isLoading) {
        return <div className="p-6">Loading...</div>;
    }

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">Gallery Management</h2>
                <button
                    onClick={() => setIsModalOpen(true)}
                    className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                >
                    <PlusIcon className="w-4 h-4 mr-2" />
                    Add Gallery
                </button>
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Photos
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Start Time
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            End Time
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Order
                        </th>
                        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {/*{galleries?.map((gallery) => (*/}
                    {/*    <tr key={gallery.id}>*/}
                    {/*        <td className="px-6 py-4">*/}
                    {/*            <div className="flex -space-x-2">*/}
                    {/*                {gallery.photoList.slice(0, 3).map((photoId) => {*/}
                    {/*                    const photo = photos?.find(p => p.id === photoId);*/}
                    {/*                    return photo ? (*/}
                    {/*                        <img*/}
                    {/*                            key={photoId}*/}
                    {/*                            src={photo.thumbnail}*/}
                    {/*                            alt={photo.title}*/}
                    {/*                            className="w-8 h-8 rounded-full border-2 border-white object-cover"*/}
                    {/*                        />*/}
                    {/*                    ) : null;*/}
                    {/*                })}*/}
                    {/*                {gallery.photoList.length > 3 && (*/}
                    {/*                    <div className="w-8 h-8 rounded-full border-2 border-white bg-gray-100 flex items-center justify-center text-xs text-gray-500">*/}
                    {/*                        +{gallery.photoList.length - 3}*/}
                    {/*                    </div>*/}
                    {/*                )}*/}
                    {/*            </div>*/}
                    {/*        </td>*/}
                    {/*        <td className="px-6 py-4 whitespace-nowrap">*/}
                    {/*            {formatDate(gallery.startTime)}*/}
                    {/*        </td>*/}
                    {/*        <td className="px-6 py-4 whitespace-nowrap">*/}
                    {/*            {formatDate(gallery.endTime)}*/}
                    {/*        </td>*/}
                    {/*        <td className="px-6 py-4 whitespace-nowrap">*/}
                    {/*            {gallery.order}*/}
                    {/*        </td>*/}
                    {/*        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">*/}
                    {/*            <button*/}
                    {/*                onClick={() => {*/}
                    {/*                    setSelectedGallery(gallery);*/}
                    {/*                    setIsModalOpen(true);*/}
                    {/*                }}*/}
                    {/*                className="text-indigo-600 hover:text-indigo-900 mr-4"*/}
                    {/*            >*/}
                    {/*                <PencilIcon className="w-4 h-4" />*/}
                    {/*            </button>*/}
                    {/*            <button*/}
                    {/*                onClick={() => handleDelete(gallery.id)}*/}
                    {/*                className="text-red-600 hover:text-red-900"*/}
                    {/*            >*/}
                    {/*                <TrashIcon className="w-4 h-4" />*/}
                    {/*            </button>*/}
                    {/*        </td>*/}
                    {/*    </tr>*/}
                    {/*))}*/}
                    </tbody>
                </table>
            </div>

            <GalleryFormModal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedGallery(null);
                }}
                onSubmit={handleSubmit}
                gallery={selectedGallery}
            />
        </div>
    );
};

export default GalleryManagement;