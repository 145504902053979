import React, {useState} from 'react';
import {MessageCircle} from 'lucide-react';
import CopyButton from "@components/common/CopyButton.tsx";
import wechatIcon from '@/assets/wechat.svg'
import lineIcon from '@/assets/line.svg'
import whatsappIcon from '@/assets/whatsapp.svg'
import {ContactInfoProps} from "@/types/props.ts";

type ContactType = 'wechat' | 'line' | 'whatsapp' | 'sms' | null;

const ContactInfo: React.FC<ContactInfoProps> = ({
                                                     phone,
                                                     wechat,
                                                     line,
                                                     whatsapp,
                                                     open_time,
                                                     close_time
                                                 }) => {
    const [activeContact, setActiveContact] = useState<ContactType>(null);

    const getContactValue = (type: ContactType) => {
        switch (type) {
            case 'wechat':
                return wechat;
            case 'line':
                return line;
            case 'whatsapp':
                return whatsapp;
            case 'sms':
                return phone;
            default:
                return '';
        }
    };

    const handleIconClick = (type: ContactType) => {
        setActiveContact(activeContact === type ? null : type);
    };

    return (
        <div className="text-center space-y-4">
            <div className="text-2xl font-bold text-pink-600">
                联系电话： {phone}
            </div>
            <div className="text-sm text-gray-500">营业时间: {open_time} - {close_time}</div>

            <div className="flex justify-center gap-8">
                {/* WeChat */}
                <div className="relative">
                    <button
                        onClick={() => handleIconClick('wechat')}
                        className={`w-12 h-12 rounded-full flex items-center justify-center ${
                            activeContact === 'wechat' ? 'bg-green-100' : 'bg-green-50'
                        }`}
                    >
                        <img src={wechatIcon} alt="WeChat" className="w-10 h-10"/>
                    </button>
                    {activeContact === 'wechat' && (
                        <div className="absolute top-full left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                            <div className="flex items-center bg-white rounded-full px-4 py-2 shadow-lg">
                                <span className="text-sm text-gray-600">{wechat}</span>
                                <CopyButton value={wechat}/>
                            </div>
                        </div>
                    )}
                </div>

                {/* Line */}
                <div className="relative">
                    <button
                        onClick={() => handleIconClick('line')}
                        className={`w-12 h-12 rounded-full flex items-center justify-center ${
                            activeContact === 'line' ? 'bg-green-100' : 'bg-green-50'
                        }`}
                    >
                        <img src={lineIcon} alt="Line" className="w-10 h-10"/>
                    </button>
                    {activeContact === 'line' && (
                        <div className="absolute top-full left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                            <div className="flex items-center bg-white rounded-full px-4 py-2 shadow-lg">
                                <span className="text-sm text-gray-600">{line}</span>
                                <CopyButton value={line}/>
                            </div>
                        </div>
                    )}
                </div>

                {/* WhatsApp */}
                <div className="relative">
                    <button
                        onClick={() => handleIconClick('whatsapp')}
                        className={`w-12 h-12 rounded-full flex items-center justify-center ${
                            activeContact === 'whatsapp' ? 'bg-green-100' : 'bg-green-50'
                        }`}
                    >
                        <img src={whatsappIcon} alt="WhatsApp" className="w-10 h-10"/>
                    </button>
                    {activeContact === 'whatsapp' && (
                        <div className="absolute top-full left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                            <div className="flex items-center bg-white rounded-full px-4 py-2 shadow-lg">
                                <span className="text-sm text-gray-600">{whatsapp}</span>
                                <CopyButton value={whatsapp}/>
                            </div>
                        </div>
                    )}
                </div>

                {/* SMS */}
                <div className="relative">
                    <button
                        onClick={() => handleIconClick('sms')}
                        className={`w-12 h-12 rounded-full flex items-center justify-center ${
                            activeContact === 'sms' ? 'bg-amber-100' : 'bg-amber-50'
                        }`}
                    >
                        <MessageCircle className="w-6 h-6 text-amber-500"/>
                    </button>
                    {activeContact === 'sms' && (
                        <div className="absolute top-full left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                            <div className="flex items-center bg-white rounded-full px-4 py-2 shadow-lg">
                                <span className="text-sm text-gray-600">{phone}</span>
                                <CopyButton value={phone}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;