import React, { useState, useEffect } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import axiosInstance from '@/services/axiosConfig';
import { useWebInfo } from "@/services/webInfoService";
import { WebInfo, WebInfoChange } from '@/types/webInfo';
import ConfirmDialog from './ConfirmDialog';
import {showInfoToast} from "@components/comments/ToastProvider.tsx";
import toast from "react-hot-toast";

const WebInfoManagement = () => {
    const queryClient = useQueryClient();
    const { data: webInfo, isLoading } = useWebInfo();
    const [editedData, setEditedData] = useState<Partial<WebInfo>>({});
    const [showConfirm, setShowConfirm] = useState(false);
    const [changes, setChanges] = useState<WebInfoChange[]>([]);

    // 初始化编辑数据
    useEffect(() => {
        if (webInfo) {
            setEditedData(webInfo);
        }
    }, [webInfo]);

    const updateMutation = useMutation({
        mutationFn: async (data: Partial<WebInfo>) => {
            const response = await axiosInstance.patch('/webinfo', data);
            return response.data;
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ['webInfo'] });
            toast.success('保存成功');
            setShowConfirm(false);
        },
        onError: () => {
            toast.error('保存失败，请重试');
        }
    });

    // 处理输入变化
    const handleInputChange = (field: keyof WebInfo, value: any) => {
        setEditedData(prev => ({ ...prev, [field]: value }));
    };

    // 处理通知相关的操作
    const handleAddNotification = () => {
        const currentNotifications = editedData.notification || [];
        setEditedData(prev => ({
            ...prev,
            notification: [...currentNotifications, '']
        }));
    };

    const handleRemoveNotification = (index: number) => {
        const newNotifications = [...(editedData.notification || [])];
        newNotifications.splice(index, 1);
        setEditedData(prev => ({ ...prev, notification: newNotifications }));
    };

    const handleNotificationChange = (index: number, value: string) => {
        const newNotifications = [...(editedData.notification || [])];
        newNotifications[index] = value;
        setEditedData(prev => ({ ...prev, notification: newNotifications }));
    };

    // 保存逻辑
    const handleSave = () => {
        if (!webInfo) return;

        // 计算变更
        const newChanges = Object.entries(editedData)
            .filter(([key, value]) => {
                const oldValue = webInfo[key as keyof WebInfo];
                return JSON.stringify(value) !== JSON.stringify(oldValue);
            })
            .map(([key, value]) => ({
                field: key,
                oldValue: webInfo[key as keyof WebInfo],
                newValue: value
            }));

        if (newChanges.length === 0) {
            showInfoToast('没有需要保存的修改');
            return;
        }

        setChanges(newChanges);
        setShowConfirm(true);
    };

    // 确认对话框的操作
    const handleConfirm = () => {
        updateMutation.mutate(editedData);
    };

    // 取消修改
    const handleCancel = () => {
        if (webInfo) {
            setEditedData(webInfo);
        }
    };

    if (isLoading || !webInfo) {
        return <div className="p-6">加载中...</div>;
    }

    return (
        <div className="p-6 space-y-6">
            <h2 className="text-xl font-semibold">网站信息</h2>

            <div className="space-y-6">
                {/* Store Name & Phone */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="p-4 border rounded-lg">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Store Name
                        </label>
                        <input
                            type="text"
                            value={editedData.store_name || ''}
                            onChange={(e) => handleInputChange('store_name', e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>

                    <div className="p-4 border rounded-lg">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Phone
                        </label>
                        <input
                            type="text"
                            value={editedData.phone || ''}
                            onChange={(e) => handleInputChange('phone', e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                </div>

                {/* Operating Hours */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="p-4 border rounded-lg">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Open Time
                        </label>
                        <input
                            type="text"
                            value={editedData.open_time || ''}
                            onChange={(e) => handleInputChange('open_time', e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>

                    <div className="p-4 border rounded-lg">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Close Time
                        </label>
                        <input
                            type="text"
                            value={editedData.close_time || ''}
                            onChange={(e) => handleInputChange('close_time', e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                </div>

                {/* Address */}
                <div className="p-4 border rounded-lg">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Address
                    </label>
                    <input
                        type="text"
                        value={editedData.address || ''}
                        onChange={(e) => handleInputChange('address', e.target.value)}
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                </div>

                {/* Notifications */}
                <div className="p-4 border rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
                            Notifications
                        </label>
                        <button
                            onClick={handleAddNotification}
                            className="text-sm text-blue-600 hover:text-blue-700"
                        >
                            Add Notification
                        </button>
                    </div>
                    <div className="space-y-2">
                        {editedData.notification?.map((notification, index) => (
                            <div key={index} className="flex gap-2">
                                <input
                                    type="text"
                                    value={notification}
                                    onChange={(e) => handleNotificationChange(index, e.target.value)}
                                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                                <button
                                    onClick={() => handleRemoveNotification(index)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Carousel Notification */}
                <div className="p-4 border rounded-lg">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Carousel Notification
                    </label>
                    <input
                        type="text"
                        value={editedData.carousel_notification || ''}
                        onChange={(e) => handleInputChange('carousel_notification', e.target.value)}
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                </div>

                {/* Description */}
                <div className="space-y-4">
                    <div className="p-4 border rounded-lg">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Description Title
                        </label>
                        <input
                            type="text"
                            value={editedData.description_title || ''}
                            onChange={(e) => handleInputChange('description_title', e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>

                    <div className="p-4 border rounded-lg">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Description Detail
                        </label>
                        <textarea
                            value={editedData.description_detail || ''}
                            onChange={(e) => handleInputChange('description_detail', e.target.value)}
                            rows={4}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                </div>

                {/* Social Media */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="p-4 border rounded-lg">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            WeChat
                        </label>
                        <input
                            type="text"
                            value={editedData.wechat || ''}
                            onChange={(e) => handleInputChange('wechat', e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>

                    <div className="p-4 border rounded-lg">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Line
                        </label>
                        <input
                            type="text"
                            value={editedData.line || ''}
                            onChange={(e) => handleInputChange('line', e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>

                    <div className="p-4 border rounded-lg">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            WhatsApp
                        </label>
                        <input
                            type="text"
                            value={editedData.whatsapp || ''}
                            onChange={(e) => handleInputChange('whatsapp', e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end gap-4 pt-4">
                <button
                    onClick={handleCancel}
                    className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                    取消
                </button>
                <button
                    onClick={handleSave}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                    保存
                </button>
            </div>

            {/* Confirmation Dialog */}
            <ConfirmDialog
                isOpen={showConfirm}
                onClose={() => setShowConfirm(false)}
                onConfirm={handleConfirm}
                changes={changes}
            />
        </div>
    );
};

export default WebInfoManagement;