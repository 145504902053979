import React, { useState } from 'react';
import PhotoManagement from '@components/admin/PhotoManagement.tsx';
import UserManagement from '@components/admin/UserManagement.tsx';
import GalleryManagement from '@components/admin/GalleryManagement.tsx';
import {TabType} from "@/types/user.ts";
import CommentModeration from "@components/comments/CommentModeration.tsx";
import {ADMIN_DASHBOARD} from "@utils/constants.ts";
import WebInfoManagement from "@components/admin/WebInfoManagement.tsx";

const AdminDashboard: React.FC = () => {
    const [activeTab, setActiveTab] = useState<TabType>('photos');

    const renderContent = () => {
        switch (activeTab) {
            case 'photos':
                return <PhotoManagement />;
            case 'users':
                return <UserManagement />;
            case 'comments':
                return <CommentModeration />;
            case 'gallery':
                return <GalleryManagement />;
            case "webinfo":
                return <WebInfoManagement />
        }
    };

    return (
        <div className="min-h-screen bg-gray-50">
            <div className="container mx-auto px-4 py-6">
                <h1 className="text-2xl font-bold mb-6">{ADMIN_DASHBOARD}</h1>

                {/* Tab Navigation */}
                <div className="border-b border-gray-200 mb-6">
                    <nav className="-mb-px flex space-x-8">
                        <button
                            onClick={() => setActiveTab('photos')}
                            className={`${
                                activeTab === 'photos'
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            } whitespace-nowrap pb-4 px-1 border-b-2 font-medium`}
                        >
                            Photos
                        </button>
                        <button
                            onClick={() => setActiveTab('users')}
                            className={`${
                                activeTab === 'users'
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            } whitespace-nowrap pb-4 px-1 border-b-2 font-medium`}
                        >
                            Users
                        </button>
                        <button
                            onClick={() => setActiveTab('comments')}
                            className={`${
                                activeTab === 'comments'
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            } whitespace-nowrap pb-4 px-1 border-b-2 font-medium`}
                        >
                            Comments
                        </button>
                        <button
                            onClick={() => setActiveTab('webinfo')}
                            className={`${
                                activeTab === 'webinfo'
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            } whitespace-nowrap pb-4 px-1 border-b-2 font-medium`}
                        >
                            WebInfo
                        </button>
                        {/*<button*/}
                        {/*    onClick={() => setActiveTab('gallery')}*/}
                        {/*    className={`${*/}
                        {/*        activeTab === 'gallery'*/}
                        {/*            ? 'border-indigo-500 text-indigo-600'*/}
                        {/*            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'*/}
                        {/*    } whitespace-nowrap pb-4 px-1 border-b-2 font-medium`}*/}
                        {/*>*/}
                        {/*    Gallery*/}
                        {/*</button>*/}
                    </nav>
                </div>

                {/* Content Area */}
                <div className="bg-white rounded-lg shadow">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;