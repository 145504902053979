import React, { useEffect } from 'react';
import { ConfirmDialogProps } from '@/types/webInfo';

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ isOpen, onClose, onConfirm, changes }) => {
    useEffect(() => {
        const handleResize = () => {
            document.documentElement.style.height = `${window.innerHeight}px`;
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!isOpen) return null;

    const formatValue = (value: any): string => {
        if (Array.isArray(value)) return value.join(', ');
        if (value === undefined || value === null) return '空';
        return String(value);
    };

    const getFieldLabel = (field: string): string => {
        const labels: Record<string, string> = {
            store_name: '店铺名称',
            phone: '电话号码',
            open_time: '开店时间',
            close_time: '关店时间',
            address: '地址',
            notification: '通知',
            carousel_notification: '轮播通知',
            description_title: '描述标题',
            description_detail: '详细描述',
            wechat: '微信',
            line: 'Line',
            whatsapp: 'WhatsApp',
        };
        return labels[field] || field;
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg max-w-2xl w-full mx-4 p-6">
                <h3 className="text-lg font-medium mb-4">确认修改</h3>

                <div className="max-h-96 overflow-y-auto">
                    <table className="w-full">
                        <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">字段</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">原值</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">新值</th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {changes.map((change, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                                <td className="px-4 py-2 text-sm">{getFieldLabel(change.field)}</td>
                                <td className="px-4 py-2 text-sm text-red-500">{formatValue(change.oldValue)}</td>
                                <td className="px-4 py-2 text-sm text-green-500">{formatValue(change.newValue)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-end gap-4 mt-6">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 text-sm"
                    >
                        取消
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm"
                    >
                        确认修改
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialog;
