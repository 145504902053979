import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Photo } from '@/types/photo';
import axiosInstance from '@/services/axiosConfig';
import { ArrowLeft, ChevronLeft, ChevronRight, Heart, X } from 'lucide-react';
import toast from 'react-hot-toast';

const PhotoDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [photo, setPhoto] = useState<Photo | null>(null);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const thumbnailsRef = useRef<HTMLDivElement>(null);
    const thumbnailWidth = 80;
    const gap = 8;
    const minSwipeDistance = 50;
    const [touchStart, setTouchStart] = useState<number | null>(null);
    const [touchEnd, setTouchEnd] = useState<number | null>(null);

    const handlePrevious = () => {
        setSelectedPhotoIndex(current =>
            current === 0 ? photo!.photo.length - 1 : current - 1
        );
    };

    const handleNext = () => {
        setSelectedPhotoIndex(current =>
            current === photo!.photo.length - 1 ? 0 : current + 1
        );
    };

    useEffect(() => {
        if (thumbnailsRef.current) {
            const scrollPosition = (thumbnailWidth + gap) * selectedPhotoIndex;
            thumbnailsRef.current.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        }
    }, [selectedPhotoIndex]);

    useEffect(() => {
        const fetchPhoto = async () => {
            if (!id) return;
            try {
                setIsLoading(true);
                setError(null);
                const response = await axiosInstance.get(`/photos/${id}`);
                setPhoto(response.data);
            } catch (err) {
                setError('Failed to load photo details');
                toast.error('Failed to load photo details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPhoto();
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const formatPrice = (price: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(price);
    };

    const onTouchStart = (e: React.TouchEvent) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e: React.TouchEvent) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            handleNext();
        }
        if (isRightSwipe) {
            handlePrevious();
        }

        setTouchStart(null);
        setTouchEnd(null);
    };

    const handleImageClick = () => {
        setIsFullscreen(!isFullscreen);
    };

    // Handle keyboard navigation in fullscreen mode
    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (!isFullscreen) return;

            if (e.key === 'Escape') {
                setIsFullscreen(false);
            } else if (e.key === 'ArrowLeft') {
                handlePrevious();
            } else if (e.key === 'ArrowRight') {
                handleNext();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [isFullscreen]);

    if (isLoading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (error || !photo) {
        return <div className="text-red-600 p-4">{error}</div>;
    }

    return (
        <>
            {/* Fullscreen Photo Viewer */}
            {isFullscreen && (
                <div
                    className="fixed inset-0 z-50 bg-black flex flex-col justify-center items-center"
                    onClick={handleImageClick}
                >
                    <button
                        className="absolute top-4 right-4 text-white z-50 p-2 hover:bg-white/10 rounded-full"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsFullscreen(false);
                        }}
                    >
                        <X className="w-6 h-6" />
                    </button>

                    <button
                        className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/10 hover:bg-white/20 p-2 rounded-full"
                        onClick={(e) => {
                            e.stopPropagation();
                            handlePrevious();
                        }}
                    >
                        <ChevronLeft className="w-6 h-6 text-white" />
                    </button>

                    <img
                        src={photo.photo[selectedPhotoIndex]}
                        alt={photo.title}
                        className="max-h-screen max-w-full object-contain mx-auto"
                        onClick={(e) => handleImageClick()}
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}
                    />

                    <button
                        className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/10 hover:bg-white/20 p-2 rounded-full"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleNext();
                        }}
                    >
                        <ChevronRight className="w-6 h-6 text-white" />
                    </button>

                    <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black/50 text-white px-3 py-1 rounded-full text-sm">
                        {selectedPhotoIndex + 1} / {photo.photo.length}
                    </div>
                </div>
            )}

            <div className="bg-gradient-to-b from-white to-pink-50">
                <div className="max-w-5xl mx-auto px-4 py-8 ">
                    <button
                        onClick={() => navigate(-1)}
                        className="flex items-center text-pink-600 hover:text-pink-700 mb-6"
                    >
                        <ArrowLeft className="w-5 h-5 mr-2"/>
                        返回
                    </button>

                    <div className="space-y-8">
                        <div className="bg-gray-100 rounded-xl shadow-lg overflow-hidden">
                            <div
                                className="relative aspect-[4/3] bg-gray-100 md:max-h-[600px] mx-auto"
                                onClick={handleImageClick}
                            >
                                <img
                                    src={photo.photo[selectedPhotoIndex]}
                                    alt={photo.title}
                                    className="w-full h-full object-contain select-none cursor-pointer"
                                    draggable="false"
                                    onTouchStart={onTouchStart}
                                    onTouchMove={onTouchMove}
                                    onTouchEnd={onTouchEnd}
                                />

                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handlePrevious();
                                    }}
                                    className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white p-2 rounded-full"
                                >
                                    <ChevronLeft className="w-6 h-6"/>
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleNext();
                                    }}
                                    className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white p-2 rounded-full"
                                >
                                    <ChevronRight className="w-6 h-6"/>
                                </button>

                                <div className="absolute bottom-4 right-4 bg-black/50 text-white px-3 py-1 rounded-full text-sm">
                                    {selectedPhotoIndex + 1} / {photo.photo.length}
                                </div>
                            </div>

                            <div className="relative p-4 bg-white">
                                <div
                                    ref={thumbnailsRef}
                                    className="flex gap-2 overflow-x-auto scrollbar-hide"
                                    style={{ scrollBehavior: 'smooth', overflowY: 'hidden' }}
                                >
                                    {photo.photo.map((url, index) => (
                                        <button
                                            key={index}
                                            onClick={() => setSelectedPhotoIndex(index)}
                                            className={`flex-shrink-0 w-20 h-20 ${
                                                selectedPhotoIndex === index
                                                    ? 'opacity-100 scale-105'
                                                    : 'opacity-50 hover:opacity-75'
                                            }`}
                                        >
                                            <img
                                                src={url}
                                                alt={`Thumbnail ${index + 1}`}
                                                className="w-full h-full object-cover rounded-lg"
                                            />
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Rest of the component remains unchanged */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div className="space-y-6">
                                {(photo.title || photo.short_description) && (
                                    <div className="bg-white rounded-xl p-6 shadow-lg">
                                        {photo.title && (
                                            <h1 className="text-2xl font-bold text-gray-800 mb-2">
                                                {photo.title}
                                            </h1>
                                        )}
                                        {photo.short_description && (
                                            <p className="text-pink-600">{photo.short_description}</p>
                                        )}
                                    </div>
                                )}

                                {(photo.age || photo.height) && (
                                    <div className="bg-white rounded-xl p-6 shadow-lg">
                                        <h2 className="text-lg font-semibold mb-4 text-gray-800">基本信息</h2>
                                        <div className="grid grid-cols-2 gap-4">
                                            {photo.age && (
                                                <div className="bg-pink-50 p-4 rounded-lg">
                                                    <div className="text-sm text-gray-500">年龄</div>
                                                    <div className="font-semibold text-pink-600">{photo.age}岁</div>
                                                </div>
                                            )}
                                            {photo.height && (
                                                <div className="bg-purple-50 p-4 rounded-lg">
                                                    <div className="text-sm text-gray-500">身高</div>
                                                    <div className="font-semibold text-purple-600">{photo.height}cm</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="space-y-6">
                                {photo.prices && photo.prices.length > 0 && (
                                    <div className="bg-white rounded-xl p-6 shadow-lg">
                                        <h2 className="text-lg font-semibold mb-4 flex items-center text-gray-800">
                                            <Heart className="w-5 h-5 text-pink-500 mr-2 fill-pink-500"/>
                                            服务项目
                                        </h2>
                                        <div className="space-y-3">
                                            {photo.prices.map((price, index) => (
                                                <div
                                                    key={index}
                                                    className="bg-gradient-to-r from-pink-50 to-purple-50 p-4 rounded-lg"
                                                >
                                                    <div className="flex justify-between items-center mb-1">
                                                        <span className="text-gray-700">{price.time}分钟</span>
                                                        <span className="text-lg font-bold text-pink-600">
                                                            ${price.price}
                                                        </span>
                                                    </div>
                                                    <p className="text-sm text-gray-600">{price.description}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {photo.detail_description && (
                                    <div className="bg-white rounded-xl p-6 shadow-lg">
                                        <h2 className="text-lg font-semibold mb-4 text-gray-800">详细介绍</h2>
                                        <p className="text-gray-600 whitespace-pre-wrap">
                                            {photo.detail_description}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PhotoDetails;