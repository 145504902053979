import toast, { Toaster } from 'react-hot-toast';
import {InfoIcon} from "lucide-react";

const ToastProvider = () => {
    return (
        <Toaster
            position="top-center"
            toastOptions={{
                // Default options
                duration: 3000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
                success: {
                    duration: 3000,
                    iconTheme: {
                        primary: '#4ade80',
                        secondary: '#fff',
                    },
                },
                error: {
                    duration: 4000,
                    iconTheme: {
                        primary: '#ef4444',
                        secondary: '#fff',
                    },
                },
            }}
        />
    );
};

export const showInfoToast = (message: string) => {
    toast.custom((t) => (
        <div
            className={`${
                t.visible ? 'animate-enter' : 'animate-leave'
            } max-w-md w-full bg-[#363636] text-white shadow-lg rounded-lg pointer-events-auto flex items-center p-4`}
        >
            <InfoIcon className="h-6 w-6 text-blue-500 mr-3" />
            <p>{message}</p>
        </div>
    ), {
        id: 'info-toast',
        duration: 3000,
    });
};

export default ToastProvider;