import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, User, X, Heart } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { HeaderProps } from "@/types/props.ts";

const Header: React.FC<HeaderProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { user, logout } = useAuth();

    return (
        <header className="bg-gradient-to-r from-pink-50 via-red-50 to-purple-50 shadow-lg">
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center h-16">
                    {/* Logo区域 */}
                    <Link to="/" className="text-xl font-bold flex items-center text-pink-600">
                        <span className="bg-gradient-to-r from-pink-600 to-purple-600 bg-clip-text text-transparent">
                            {props.title}
                        </span>
                    </Link>

                    {/* Mobile menu button */}
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="md:hidden p-2 text-pink-600 hover:text-pink-700"
                    >
                        {isOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>

                    {/* Desktop Navigation */}
                    <nav className="hidden md:flex items-center space-x-4">
                        <Link
                            to="/"
                            className="px-4 py-2 rounded-full hover:bg-white/50 text-pink-600 hover:text-pink-700 transition-colors duration-200"
                        >
                            Home
                        </Link>
                        {user?.role === 'ADMIN' && (
                            <Link
                                to="/admin"
                                className="px-4 py-2 rounded-full hover:bg-white/50 text-pink-600 hover:text-pink-700 transition-colors duration-200"
                            >
                                Admin
                            </Link>
                        )}
                        {user ? (
                            <>
                                <button
                                    onClick={logout}
                                    className="px-4 py-2 rounded-full hover:bg-white/50 text-pink-600 hover:text-pink-700 transition-colors duration-200"
                                >
                                    Logout
                                </button>
                                <div className="flex items-center space-x-2 px-4 py-2 bg-white/30 backdrop-blur-sm rounded-full">
                                    <User size={20} className="text-pink-500" />
                                    <span className="text-pink-600">{user.username}</span>
                                </div>
                            </>
                        ) : (
                            <Link
                                to="/login"
                                className="px-4 py-2 bg-gradient-to-r from-pink-500 to-purple-500 text-white rounded-full hover:from-pink-600 hover:to-purple-600 transition-colors duration-200"
                            >
                                Login
                            </Link>
                        )}
                    </nav>
                </div>

                {/* Mobile Navigation */}
                {isOpen && (
                    <div className="md:hidden py-4 bg-white/50 backdrop-blur-sm rounded-xl mt-2 shadow-lg">
                        <div className="flex flex-col space-y-2">
                            <Link
                                to="/"
                                className="px-4 py-2 hover:bg-white/50 text-pink-600 hover:text-pink-700"
                                onClick={() => setIsOpen(false)}
                            >
                                Home
                            </Link>
                            {user?.role === 'ADMIN' && (
                                <Link
                                    to="/admin"
                                    className="px-4 py-2 hover:bg-white/50 text-pink-600 hover:text-pink-700"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Admin
                                </Link>
                            )}
                            {user ? (
                                <>
                                    <button
                                        onClick={() => {
                                            logout();
                                            setIsOpen(false);
                                        }}
                                        className="px-4 py-2 hover:bg-white/50 text-pink-600 hover:text-pink-700 text-left"
                                    >
                                        Logout
                                    </button>
                                    <div className="flex items-center space-x-2 px-4 py-2 text-pink-600">
                                        <User size={20} className="text-pink-500" />
                                        <span>{user.username}</span>
                                    </div>
                                </>
                            ) : (
                                <Link
                                    to="/login"
                                    className="mx-4 py-2 bg-gradient-to-r from-pink-500 to-purple-500 text-white text-center rounded-full hover:from-pink-600 hover:to-purple-600"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Login
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;