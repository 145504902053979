import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Home from '@/pages/Home';
import Login from '@/pages/Login';
import Register from '@/pages/Register';
import PhotoDetails from '@/pages/PhotoDetails';
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import PrivateRoute from "@components/admin/PrivateRoute.tsx";
import {useWebInfo} from "@services/webInfoService.ts";
import AdminDashboard from "@pages/AdminDashboard.tsx";

const App: React.FC = () => {
    const {data: webInfo, isLoading: isLoadingWebInfo, error: webInfoError} = useWebInfo()

    return (
        <div className="min-h-screen flex flex-col">
            <Header title={webInfo?.store_name}/>
            <main className="flex-grow container mx-auto px-4 py-8">
                <Routes>
                    <Route path="/" element={<Home webInfo={webInfo}/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/photo/:id" element={<PhotoDetails/>}/>
                    <Route
                        path="/admin"
                        element={
                            <PrivateRoute adminOnly>
                                <AdminDashboard/>
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </main>
            <Footer webInfo={webInfo}/>
        </div>

    );
};

export default App;