import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Gallery } from '@/types/gallery';
import { usePhotos } from '@/services/photoService';
import { Photo } from '@/types/photo';
import toast from "react-hot-toast";
import {GalleryFormModalProps} from "@/types/props.ts";

const GalleryFormModal: React.FC<GalleryFormModalProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               onSubmit,
                                                               gallery
                                                           }) => {
    const { data: photos } = usePhotos();
    const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [order, setOrder] = useState('0');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (gallery) {
            setSelectedPhotos(gallery.photoList);
            setStartTime(gallery.startTime.split('T')[0]);
            setEndTime(gallery.endTime.split('T')[0]);
            setOrder(String(gallery.order));
        } else {
            setSelectedPhotos([]);
            setStartTime('');
            setEndTime('');
            setOrder('0');
        }
    }, [gallery]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (selectedPhotos.length === 0) {
            toast.error('请至少添加一张照片')
            return;
        }

        setIsSubmitting(true);

        try {
            const formData: Partial<Gallery> = {
                photoList: selectedPhotos,
                startTime: new Date(startTime).toISOString(),
                endTime: new Date(endTime).toISOString(),
                order: parseInt(order)
            };

            await onSubmit(formData);
            onClose();
        } catch (error) {
            console.error('Error submitting gallery:', error);
            toast.error('保存失败，请重试')
        } finally {
            setIsSubmitting(false);
        }
    };

    const togglePhotoSelection = (photoId: string) => {
        setSelectedPhotos(prev =>
            prev.includes(photoId)
                ? prev.filter(id => id !== photoId)
                : [...prev, photoId]
        );
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-6 border-b">
                    <h2 className="text-xl font-semibold">
                        {gallery ? 'Edit Gallery' : 'Create New Gallery'}
                    </h2>
                    <button
                        onClick={onClose}
                        disabled={isSubmitting}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <X className="w-6 h-6" />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="p-6 space-y-6">
                    {/* Time Range */}
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Start Time
                            </label>
                            <input
                                type="datetime-local"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                disabled={isSubmitting}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                End Time
                            </label>
                            <input
                                type="datetime-local"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                disabled={isSubmitting}
                            />
                        </div>
                    </div>

                    {/* Order */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Display Order
                        </label>
                        <input
                            type="number"
                            value={order}
                            onChange={(e) => setOrder(e.target.value)}
                            required
                            min="0"
                            className="mt-1 block w-40 border border-gray-300 rounded-md shadow-sm p-2"
                            disabled={isSubmitting}
                        />
                    </div>

                    {/* Photo Selection */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Select Photos ({selectedPhotos.length} selected)
                        </label>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                            {photos?.map((photo: Photo) => (
                                <div
                                    key={photo.id}
                                    onClick={() => !isSubmitting && togglePhotoSelection(photo.id)}
                                    className={`
                    relative rounded-lg overflow-hidden cursor-pointer
                    ${selectedPhotos.includes(photo.id) ? 'ring-2 ring-indigo-500' : ''}
                    ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}
                  `}
                                >
                                    <img
                                        src={photo.thumbnail}
                                        alt={photo.title}
                                        className="w-full h-32 object-cover"
                                    />
                                    {selectedPhotos.includes(photo.id) && (
                                        <div className="absolute inset-0 bg-indigo-500 bg-opacity-20"></div>
                                    )}
                                    <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/60 to-transparent">
                                        <p className="text-white text-sm truncate">{photo.title}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Submit Buttons */}
                    <div className="flex justify-end space-x-4 pt-4 border-t">
                        <button
                            type="button"
                            onClick={onClose}
                            disabled={isSubmitting}
                            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {isSubmitting
                                ? (gallery ? 'Saving...' : 'Creating...')
                                : (gallery ? 'Save Changes' : 'Create Gallery')
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default GalleryFormModal;