import axiosInstance from './axiosConfig';
import { useQuery } from '@tanstack/react-query';
import {GET_ALL_PHOTOS, GET_PHOTOS} from "@utils/urls.ts";
import {Photo} from "@/types/photo.ts";

/**
 * useQuery<Photo[]>({
 *   queryKey: ['photos'],
 *   queryFn: fetchPhotos,
 *   staleTime: 5000, // 数据5秒后过期
 * });
 *
 * 默认情况下：
 *
 * staleTime: 0 (立即认为数据过期)
 * 组件重新挂载会触发重新获取
 * 窗口重新获得焦点会触发重新获取
 */
export const usePhotos = () => {
    return useQuery({
        queryKey: ['photos'],
        queryFn: async (): Promise<Photo[]> => {
            const response = await axiosInstance.get(GET_PHOTOS);
            return response.data;
        }
    });
};

export const useAllPhotos = () => {
    return useQuery({
        queryKey: ['photos', 'all'],
        queryFn: async (): Promise<Photo[]> => {
            const response = await axiosInstance.get(GET_ALL_PHOTOS);
            return response.data;
        }
    });
};

export const usePhoto = (id: string) => {
    return useQuery({
        queryKey: ['photo', id],
        queryFn: async () => {
            const response = await axiosInstance.get(`${GET_PHOTOS}${id}`);
            return response.data;
        },
        enabled: !!id
    });
};