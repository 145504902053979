import React from 'react';
import { Sparkles } from 'lucide-react';

interface ScrollingNoticeProps {
    text: string;
}

const ScrollingNotice: React.FC<ScrollingNoticeProps> = ({ text }) => {
    return (
        <div className="bg-pink-50/50 backdrop-blur-sm overflow-hidden">
            <div className="flex items-center py-3 animate-scroll">
                <div className="flex items-center whitespace-nowrap px-4">
                    <Sparkles className="w-5 h-5 text-pink-500 mr-2" />
                    <span className="text-gray-700">{text}</span>
                </div>
                <div className="flex items-center whitespace-nowrap px-4">
                    <Sparkles className="w-5 h-5 text-pink-500 mr-2" />
                    <span className="text-gray-700">{text}</span>
                </div>
            </div>
        </div>
    );
};

export default ScrollingNotice;