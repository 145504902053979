import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';
import toast from 'react-hot-toast';
import {CopyButtonProps} from "@/types/props.ts";

const CopyButton: React.FC<CopyButtonProps> = ({ value, className }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(value);
            setCopied(true);
            toast.success('复制成功');
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            toast.error('复制失败');
        }
    };

    return (
        <button
            onClick={handleCopy}
            className={`inline-flex items-center justify-center p-2 rounded-full bg-white/50 hover:bg-white/80 transition-all duration-300 ${className}`}
        >
            {copied ? (
                <Check className="w-4 h-4 text-green-500" />
            ) : (
                <Copy className="w-4 h-4 text-pink-500" />
            )}
        </button>
    );
};

export default CopyButton;