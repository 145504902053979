import React, { createContext, useContext, useState, useEffect } from 'react';
import {User} from "@/types/user";
import {AuthContextType} from "@/types/auth";
import axiosInstance from "@/services/axiosConfig";
import {GET_ME} from "@utils/urls.ts";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const login = (token: string) => {
        localStorage.setItem('accessToken', token);
        checkAuth();
    };

    const logout = () => {
        localStorage.removeItem('accessToken');
        setUser(null);
    };

    const checkAuth = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                setIsLoading(false);
                return;
            }

            const response = await axiosInstance.get(GET_ME);
            setUser(response.data);
        } catch (error) {
            logout();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ user, isLoading, login, logout, checkAuth }}>
            {children}
        </AuthContext.Provider>
);
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}