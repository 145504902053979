import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from './axiosConfig';
import { Gallery } from '@/types/gallery';
import {Photo} from "@/types/photo.ts";

export const useGalleries = () => {
    return useQuery<Photo[]>({
        queryKey: ['galleries'],
        queryFn: async () => {
            const response = await axiosInstance.get('/photos/all');
            return response.data;
        }
    });
};

export const useCreateGallery = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (galleryData: Partial<Gallery>) => {
            const response = await axiosInstance.post('/galleries', galleryData);
            return response.data;
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ['galleries'] });
        }
    });
};

export const useUpdateGallery = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, data }: { id: string; data: Partial<Gallery> }) => {
            const response = await axiosInstance.put(`/galleries/${id}`, data);
            return response.data;
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ['galleries'] });
        }
    });
};

export const useDeleteGallery = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            await axiosInstance.delete(`/galleries/${id}`);
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ['galleries'] });
        }
    });
};