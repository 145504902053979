import axiosInstance from './axiosConfig';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { WEBINFO } from "@utils/urls.ts";
import { WebInfo } from "@/types/webInfo.ts";

export const useWebInfo = () => {
    return useQuery({
        queryKey: ['webInfo'],
        queryFn: async (): Promise<WebInfo> => {
            const { data } = await axiosInstance.get(WEBINFO);
            return data;
        }
    });
};